import React from 'react';
import Gallery from '@tra-sg/gatsby-theme-c360-portal/src/components/Gallery';

const StatusPage = (props) => (
  <Gallery
    metaTitle="Status | c360"
    title="Status"
    dashboardName="status-page"
    pageComponent={props}
  />
);

export default StatusPage;
